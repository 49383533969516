body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.font-bold {
  font-family: "'BoutrosMBCDinkum Medium'";
  font-weight: bold;
}

.link{
  color: #2d303e;
}

.error-status-color-0 {color:#a875ff}
.error-status-color-1 {color:#ff4081}
.error-status-color-2 {color:#e50000}
.error-status-color-3 {color:#6BC950}
.error-status-color-4 {color:#D3D3D3}
.error-status-color-5 {color:#2ECD6F}

.logs-level-debug {color: #A35200}
.logs-level-info {color: #1890FF}
.logs-level-warn {color: #FAAD14}
.logs-level-error {color: #F5222D}
.logs-level-fatal { color: #FF1010}