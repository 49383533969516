
.owl-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: flex-start;
    gap: 13px;
    margin-bottom: 200px;
}

 .owl-larg-grid{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: flex-start;
    gap: 13px;
}

@media screen and (min-width: 1280px) {
    .owl-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
    .big-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media screen and (min-width: 2500px) {
    .owl-grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .owl-larg-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}


input::placeholder {
    color: rgb(156 163 175);
    letter-spacing: 1px;
    font-size: 14px;
    z-index: 2;
    font-weight: 300;
    position: relative;
    background-color: #fff;
}

.owl-query-conainer {
    flex-direction: column;
    display: flex;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 0.5rem;
    background-color: #fafafa;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.owl-query-section {
    padding: 16px 6px;
    flex-wrap: wrap;
    display: flex;
    gap: 12px;    
    align-items: center;
    border-radius: 0.5rem;
    /* border:  1px solid #ccc; */
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.owl-query-cleaner{
    flex-wrap:  wrap;
    display:  flex;
    gap: 10px;
    box-shadow: inset 0px 0px 8px #f8717155;
    border-radius: 0.5rem;
    margin-top: 10px;
    padding: 10px;
}

.owl-label-conatiner{
    flex-direction: row;
    align-items: center;
    max-width: 160px;
    display:  flex ;
    flex-grow: 1;
    gap: 5px;
    padding: 9px;
    border-radius: 0.5rem;
    background-color: #ffffff;
    justify-content: space-between;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.owl-label{
    color: rgb(156 163 175);
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 300;
    margin-block: -20px;
    font-family: "'BoutrosMBCDinkum Medium'";
}


.owl-button{
    font-family: "'BoutrosMBCDinkum Medium'";
    background-color: #ffffff;
    color: rgb(248 113 113);
    border-radius: 0.5rem;
    padding: 4px 16px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.owl-button-active{
    background-color: rgb(248 113 113);
    color: #ffffff;
}

.close-popup{
    /* background-color: #0004; */
    inset: 0;
    z-index: 1;
    position: fixed;
}

.owl-button-conatiner{
    position: relative;
}

.owl-popup-container{
}

.owl-close-space{
    position: absolute;
    margin-top: 10px;
    margin: -50px;
    padding: 50px;
    z-index: 999;
}

.owl-popup{
    display: flex;
    flex-direction:column;
    gap: 12px;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    padding: 12px 24px;
    color: #f87171;
}

.hide {
    display: none;
}

.clear-icon{
    color: rgb(252, 165, 165);
    font-size: 20px;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: 300;
    right: -18px;
    position: relative;
    cursor: pointer;
}

.owl-input {
    font-family: "'BoutrosMBCDinkum Medium'";
    padding: 10px;
    outline: none;
    border: none;
    color: #2d303e;
    font-weight: bold;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.owl-input:focus{box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);}

.owl-date{
    outline: none;
    border: none;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
    color: #2d303e;

}

.owl-buttons-options {
    /* color: #fff; */
    background-color: #f9f9f9;
    flex-direction: row;
    display: flex;
    gap: 10px;
    padding:5px;
    border-radius: 0.5rem;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.16);
}


.owl-options {
    /* color: #fff; */
    /* background-color: #2d303e; */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    padding:  8px;
    outline: none;
    border: none;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
}

.owl-toggle-button{
    display: flex;
    margin-inline: 8px;
    align-items: center;
    flex-direction: row;
    margin-block:auto;
}

.owl-toggleed-bg{
    cursor: pointer;
    padding: 2px;
    display: flex;
    margin: 0 4px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
    padding-left: 22px;
}
.owl-toggleed-bg span{
   padding: 10px;
   border-radius: 24px;
   background-color: #ffffff;
   box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
 }

.owl-toggleed {
    padding-right: 22px;
    padding-left: 2px;
    background-color: #F87171;
}

.shadow-1 {
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
}

/* Consts */
.item-builder{
    font-family: "'BoutrosMBCDinkum Medium'";
    flex-direction: column;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: 13px;
    padding: 12px;
    display: flex;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
    overflow-x: auto;
}

.obj{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2px 10px;
    border-radius: 0.5rem;
    background-color: #ffffff;
    /* border: 1px solid #e0e0e0; */
    /* align-items: center; */
    /* box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16); */
    margin: 4px;
    cursor: grab;
    align-self: flex-start;
}

@media screen and (max-width: 768px) {
    .owl-popup{
        left: 10px;
        right: 10px;
        z-index: 999;
        position: fixed;
    }
 
    
}





/* Theme */
.flex-grow {flex-grow: 1;}
.row{display:flex; flex-direction: row;}
.row-center{display:flex; flex-direction: row;align-items: center;}
.col{display:flex; flex-direction: column;}
.col-center{display:flex; flex-direction: column;align-items: center;}
.wrap{display: flex; flex-wrap: wrap;}
.overflow-auto{overflow: auto;}
.overflow-hidden{overflow: hidden;}
.h-screen{max-height: 100vh;}
.w-screen{width: 100vw;}

.col-span-full{grid-column: 1 / -1;}

.absolute{position: absolute;}
.fixed{position: fixed;}
.relative{position: relative;}
.inset-0{inset: 0;}
.right-0{right: 0;}
.left-0{left: 0;}
.top-0{top: 0;}
.bottom-0{bottom: 0;}

.z-10{z-index: 1;}
.z-20{z-index: 2;}
.z-30{z-index: 3;}
.z-40{z-index: 4;}
.z-50{z-index: 5;}

.items-center {align-items: center;}
.items-start {align-items: flex-start;}
.items-end {align-items: flex-end;}
.justify-center {justify-content: center;}
.justify-start {justify-content: flex-start;}
.justify-end {justify-content: flex-end;}
.justify-between {justify-content: space-between;}
.justify-around {justify-content: space-around;}
.justify-evenly {justify-content: space-evenly;}
.self-start {align-self: flex-start;}
.self-center {align-self: center;}


.min-w-max{min-width: max-content;}

.shadow-gray{box-shadow: 0px 0px 6px rgba(107 ,114 ,128, 0.5);}
.shadow-cyan{box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);}
.shadow-red{box-shadow: 0px 0px 6px rgba(252, 165, 165,0.5);}
.shadow-purple{box-shadow: 0px 0px 6px rgba(168, 85 ,247,0.5);}

.border-gray{border: solid 1px #D1D5DB ;}
.border-cayn{border: solid 1px rgb(32, 211, 238) }
.border-red{border: solid 1px rgb(252, 165, 165) }

.owl-text-xs{font-size: 0.75rem;}
.owl-text-sm{font-size: 0.875rem;}
.owl-text-base{font-size: 1rem;}
.owl-text-lg{font-size: 1.125rem;}
.owl-text-xl{font-size: 1.25rem;}
.owl-text-2xl{font-size: 1.5rem;}
.owl-text-3xl{font-size: 2rem;}


.p-1 {padding:4px;}
.p-2 {padding:8px;}
.p-3 {padding:12px;}
.p-4 {padding:16px;}
.p-5 {padding:20px;}
.p-6 {padding:24px;}
.p-7 {padding:28px;}
.p-8 {padding:32px;}
.p-9 {padding:36px;}
.p-10 {padding:40px;}
.p-11 {padding:44px;}
.p-12 {padding:48px;}
.p-13 {padding:52px;}
.p-14 {padding:56px;}
.p-15 {padding:60px;}

.px-1 {padding-inline:4px;}
.px-2 {padding-inline:8px;}
.px-3 {padding-inline:12px;}
.px-4 {padding-inline:16px;}
.px-5 {padding-inline:20px;}
.px-6 {padding-inline:24px;}
.px-7 {padding-inline:28px;}
.px-8 {padding-inline:32px;}
.px-9 {padding-inline:36px;}
.px-10 {padding-inline:40px;}
.px-11 {padding-inline:44px;}
.px-12 {padding-inline:48px;}
.px-13 {padding-inline:52px;}
.px-14 {padding-inline:56px;}
.px-15 {padding-inline:60px;}
.px-auto {padding-inline:auto;}

.m-1 {margin:4px;}
.m-2 {margin:8px;}
.m-3 {margin:12px;}
.m-4 {margin:16px;}
.m-5 {margin:20px;}
.m-6 {margin:24px;}
.m-7 {margin:28px;}
.m-8 {margin:32px;}
.m-9 {margin:36px;}
.m-10 {margin:40px;}
.m-11 {margin:44px;}
.m-12 {margin:48px;}
.m-13 {margin:52px;}
.m-14 {margin:56px;}
.m-15 {margin:60px;}

.mx-1 {margin-inline:4px;}
.mx-2 {margin-inline:8px;}
.mx-3 {margin-inline:12px;}
.mx-4 {margin-inline:16px;}
.mx-5 {margin-inline:20px;}
.mx-6 {margin-inline:24px;}
.mx-7 {margin-inline:28px;}
.mx-8 {margin-inline:32px;}
.mx-9 {margin-inline:36px;}
.mx-10 {margin-inline:40px;}
.mx-11 {margin-inline:44px;}
.mx-12 {margin-inline:48px;}
.mx-13 {margin-inline:52px;}
.mx-14 {margin-inline:56px;}
.mx-15 {margin-inline:60px;}
.mx-auto {margin-inline:auto;}

.gap-1 {gap:4px;}
.gap-2 {gap:8px;}
.gap-3 {gap:12px;}
.gap-4 {gap:16px;}
.gap-5 {gap:20px;}
.gap-6 {gap:24px;}
.gap-7 {gap:28px;}
.gap-8 {gap:32px;}
.gap-9 {gap:36px;}
.gap-10 {gap:40px;}
.gap-11 {gap:44px;}
.gap-12 {gap:48px;}
.gap-13 {gap:52px;}
.gap-14 {gap:56px;}
.gap-15 {gap:60px;}
 
.rounded-none	{border-radius: 0px;}
.rounded-sm	{border-radius: 0.125rem; }
.rounded	{border-radius: 0.25rem; }
.rounded-md	{border-radius: 0.375rem; }
.rounded-lg	{border-radius: 0.5rem; }
.rounded-xl	{border-radius: 0.75rem; }
.rounded-2xl	{border-radius: 1rem; }
.rounded-3xl	{border-radius: 1.5rem; }
.rounded-full	{border-radius: 9999px;}




.text-left{text-align: left;}
.text-int-1{color: rgb(46, 205, 111);}
.text-string-1{color:rgb(255, 64, 129)}

.text-cyan-50   {color: rgb(236 254 255);}
.text-cyan-100  {color: rgb(207 250 254);}
.text-cyan-200  {color: rgb(165 243 252);}
.text-cyan-300  {color: rgb(103 232 249);}
.text-cyan-400  {color: #22d3ee;}
.text-cyan-500  {color: rgb(6 182 212);}
.text-cyan-600  {color: rgb(8 145 178);}
.text-cyan-700  {color: rgb(14 116 144);}
.text-cyan-800  {color: rgb(21 94 117);}
.text-cyan-900  {color: rgb(22 78 99);}
.text-red-50    {color: rgb(254 242 242);}
.text-red-100   {color: rgb(254 226 226);}
.text-red-200   {color: rgb(254 202 202);}
.text-red-300   {color: rgb(252 165 165);}
.text-red-400   {color: rgb(248 113 113);}
.text-red-500   {color: rgb(239 68 68);}
.text-red-600   {color: rgb(220 38 38);}
.text-red-700   {color: rgb(185 28 28);}
.text-red-800   {color: rgb(153 27 27);}
.text-red-900   {color: rgb(127 29 29);}
.text-gray-50   {color: rgb(249 250 251);}
.text-gray-100  {color: rgb(243 244 246);}
.text-gray-200  {color: rgb(229 231 235);}
.text-gray-300  {color: rgb(209 213 219);}
.text-gray-400  {color: rgb(156 163 175);}
.text-gray-500  {color: rgb(107 114 128);}
.text-gray-600  {color: rgb(75 85 99);}
.text-gray-700  {color: rgb(55 65 81);}
.text-gray-800  {color: rgb(31 41 55);}
.text-gray-900  {color: rgb(17 24 39);}
.text-black     {color: rgb(0 0 0);}
.text-white     {color: rgb(255 255 255);}
.text-purple-50     {color: rgb(250 245 255);}
.text-purple-100    {color: rgb(243 232 255);}
.text-purple-200    {color: rgb(233 213 255);}
.text-purple-300    {color: rgb(216 180 254);}
.text-purple-400    {color: rgb(192 132 252);}
.text-purple-500    {color: rgb(168 85 247);}
.text-purple-600    {color: rgb(147 51 234);}
.text-purple-700    {color: rgb(126 34 206);}
.text-purple-800    {color: rgb(107 33 168);}
.text-purple-900    {color: rgb(88 28 135);}


.text-black     {background-color :rgb(0 0 0);}
.text-white     {background-color :rgb(255 255 255);}

.bg-cyan-50   {background-color: rgb(236 254 255);}
.bg-cyan-100  {background-color: rgb(207 250 254);}
.bg-cyan-200  {background-color: rgb(165 243 252);}
.bg-cyan-300  {background-color: rgb(103 232 249);}
.bg-cyan-400  {background-color: #22d3ee;}
.bg-cyan-500  {background-color: rgb(6 182 212);}
.bg-cyan-600  {background-color: rgb(8 145 178);}
.bg-cyan-700  {background-color: rgb(14 116 144);}
.bg-cyan-800  {background-color: rgb(21 94 117);}
.bg-cyan-900  {background-color: rgb(22 78 99);}

.bg-red-50    {background-color: rgb(254 242 242);}
.bg-red-100   {background-color: rgb(254 226 226);}
.bg-red-200   {background-color: rgb(254 202 202);}
.bg-red-300   {background-color: rgb(252 165 165);}
.bg-red-400   {background-color: rgb(248 113 113);}
.bg-red-500   {background-color: rgb(239 68 68);}
.bg-red-600   {background-color: rgb(220 38 38);}
.bg-red-700   {background-color: rgb(185 28 28);}
.bg-red-800   {background-color: rgb(153 27 27);}
.bg-red-900   {background-color: rgb(127 29 29);}

.bg-gray-50   {background-color: rgb(249 250 251);}
.bg-gray-100  {background-color: rgb(243 244 246);}
.bg-gray-200  {background-color: rgb(229 231 235);}
.bg-gray-300  {background-color: rgb(209 213 219);}
.bg-gray-400  {background-color: rgb(156 163 175);}
.bg-gray-500  {background-color: rgb(107 114 128);}
.bg-gray-600  {background-color: rgb(75 85 99);}
.bg-gray-700  {background-color: rgb(55 65 81);}
.bg-gray-800  {background-color: rgb(31 41 55);}
.bg-gray-900  {background-color: rgb(17 24 39);}

.bg-black     {background-color :rgb(0 0 0);}
.bg-white     {background-color :rgb(255 255 255);}

.rounded-none	{border-radius: 0px;}
.rounded-sm	{border-radius: 0.125rem; }
.rounded	{border-radius: 0.25rem; }
.rounded-md	{border-radius: 0.375rem; }
.rounded-lg	{border-radius: 0.5rem; }
.rounded-xl	{border-radius: 0.75rem; }
.rounded-2xl	{border-radius: 1rem; }
.rounded-3xl	{border-radius: 1.5rem; }
.rounded-full	{border-radius: 9999px;}

.bg-purple-50     {background-color: rgb(250 245 255);}
.bg-purple-100    {background-color: rgb(243 232 255);}
.bg-purple-200    {background-color: rgb(233 213 255);}
.bg-purple-300    {background-color: rgb(216 180 254);}
.bg-purple-400    {background-color: rgb(192 132 252);}
.bg-purple-500    {background-color: rgb(168 85 247);}
.bg-purple-600    {background-color: rgb(147 51 234);}
.bg-purple-700    {background-color: rgb(126 34 206);}
.bg-purple-800    {background-color: rgb(107 33 168);}
.bg-purple-900    {background-color: rgb(88 28 135);}









