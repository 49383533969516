#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    min-height: 100vh;
}
#main-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.wrapper {
    background-color: #ecf0f3;
    text-align: right;
    overflow-x: hidden;
    height: 100%;
}

body {
    background-color: #ecf0f3;
    overflow-x: hidden;
}
/* * {
    transition: all 250ms ease-in-out;
} */

.flexRow {
    display: flex;
    flex-direction: row;
}


/* 
.font-bold {
    font-family: "Tajawal", sans-serif;
    font-weight: bold;
    color: #2c3e50;
} */
.js-bt {
    justify-content: space-between;
}
.js-around {
    justify-content: space-around;
}

.logs-card {
    padding-inline: 10px;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    /* padding-bottom: 15px; */
    /* background-color: #f0f0f0; */
    background-color: #f9fafb;
}
.logs-card .text {
    /* color: #343849; */
    color: #2c3e50;
}
.logs-card-text {
    font-size: 14px;
}
.logs-card-title {
    font-size: 18px;
    /* min-width: 100px; */
    text-align: left;
    color: rgb(185, 28, 28);
    /* : 0px 0px 8px rgba(0, 0, 0, 0.16); */
}

.active-card {
    background-color:#F87171aa;
}


.logs-card-header {
    display: flex;
    text-align: center;
    justify-content: space-between;
    /* background-color: rgb(240, 240, 240); */
    /* background-color: #fff; */
    /* margin-bottom: 16px; */
    align-items: center;
    font-size: 18px;
    /* padding: 10px 20px; */
}

.logs-level {
    color: #fff;
    padding: 5px 8px;
    width: 20px;
    border-radius: 40px;
}

.logs-card-content {
    padding: 10px 0;
    /* border-top: 1px solid #ecf0f3; */
    font-family: "Tajawal", sans-serif;
    font-weight: bold;
    font-size: 15px;
    color: #2c3e50;
    direction: ltr;

    max-height: 1800px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: #ecf9f9;
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16); */
    border: 2px dashed #5f8b89;
    padding:10px 30px;
    margin:10px 0;
    /* border-radius:  40px 0px 40px 40px; */
    border-radius: 15px;
}

.cut-words {
    max-height: 105px;
}

.Grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: flex-start;
    gap: 13px;
    margin-bottom: 200px;
    /* user-select: none; */
}
 .larg-grid{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: flex-start;
    gap: 13px;
    /* user-select: none; */
}

@media screen and (min-width: 1280px) {
    .Grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
    .big-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
@media screen and (min-width: 2500px) {
    .Grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .larg-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}
.input-conatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input-lable {
    font-size: 18px;
    padding-left: 8px;
    color: rgb(75, 85, 99);
}
.input-field {
    padding: 10px;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.drowp-field {
    padding: 4px 8px;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    color: #fff;
    outline: none;
    background-color: #2d303e;
}
.green-btn {
    background-color: rgb(16, 185, 129);
    padding: 8px 20px;
    font-size: 18px;
    color: #fff;
    border-radius: 10px;
    margin: 8px;
    font-family: "Tajawal", sans-serif;
    font-weight: bold;
}

.logs-card-lables {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
}


.dongle-type{
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
}

.main .dongle-type{
    /* background-color: rgb(168, 117, 255,0.9); */
    /* color: #f9fafb; */
    color: rgb(168, 117, 255,0.9);
    box-shadow: 0px 0px 6px rgb(168, 117, 255);
}

.blocked .dongle-type {
    background-color: rgb(224, 70, 82,0.9);
    box-shadow: 0px 0px 6px rgb(224, 70, 82);
    color: #f9fafb;
}

/* 
@media screen and (max-width: 730px) {
    .Grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
} */

.remove-btn2 {
    user-select: none;
    background-color: #fff;
    font-family: "Tajawal", sans-serif;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
}
.remove-btn2 .x {
    color: red;
    font-family: "Tajawal", sans-serif;
    font-weight: bold;
    font-size: 20px;
    padding: 0 10px;
}

/* Errors Messages */

.logs-card-errorlables {
    min-width: 120px;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
    margin: 5px;
    cursor: default;
}
.logs-card-error-title {
    font-size: 20px;
    /* color: rgb(185, 28, 28); */
}

.popup-Message-container {
    position: fixed;
    font-family: "Tajawal", sans-serif;
    font-weight: bold;
    font-size: 18px;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3333;
}

.popup-Message {
    background-color: #fff;

    /* width: 80%; */
    min-width: 750px;
    /* min-height: 450px; */
    max-width: 1400px;
    padding: 25px;
    border-radius: 10px;
    word-wrap: break-word;
    text-align: left;
    max-height: 80vh;
    overflow: auto;
}
.fade-out {
    animation: fade-out 0.15s ease-out;
    background-color: #0000;
    opacity: 0;
}
.fade-in {
    animation: fade-in 0.15s ease-out;
    background-color: #000c;
}

@keyframes fade-in {
    from {
        opacity: 0;
        background-color: #0000;
    }
    to {
        opacity: 1;
        background-color: #0007;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
        background-color: #0007;
    }
    to {
        opacity: 0;
        background-color: #0000;
    }
}

.pointer {
    cursor: pointer;
}

.error-status-color-0 {color:#a875ff}
.error-status-color-1 {color:#ff4081}
.error-status-color-2 {color:#e50000}
.error-status-color-3 {color:#6BC950}
.error-status-color-4 {color:#D3D3D3}
.error-status-color-5 {color:#2ECD6F}

.un-active-company   {
    background-color: #f9fafb;
    color: #343849;
}

.un-active-company {
    background-color: #fff;
    color: #343849;
 }


.active-company {
    background-color: #F87171;
 }
 
 .active-company p   {
     color: white;
  }

  .active-company .card {
     background-color: #fff;
   }
  .active-company .card p {
     color: #343849;
  }

  .deleted .logs-card .text {
    color: #ff5151;
  }