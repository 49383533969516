:root {
    --height: 0;
}
.scroller::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.height-full {
    height: var(--height);
}

.logs-container {
    padding: 10px;
    padding-top: 16px;
    padding-bottom: 220px;
    background-color: #ecf0f3cc;
}

.versionIcon {
    height: 40px;
}
.overHideen {
    overflow: hidden;
}

.shadow-1 {
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
}


.btn {
    padding: 10px 20px;
    color: #fff;
    margin: 5px;
    border-radius: 10px;
}

.firebrick-btn {
    background-color: firebrick;
}
.gray-btn {
    background-color: lightslategrey;
}

.text-1 {
    font-family: "Tajawal", sans-serif;
    padding: 6px 16px;
    font-size: 18px;
}
.text-2 {
    font-family: "Tajawal", sans-serif;
    padding: 6px 16px;
    font-size: 18px;
    color: #fff;
}

.popHolder {
    background-color: #fff;
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    max-height: 80vh;
    overflow-y: auto;
}


.selectble {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    color: #2d303e;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
    overflow: hidden;
}
.selectble:hover .title {
    color: #fff;
}
.selectble .dynimc-bg {
    left: 10px;
    right: 92%;
    top: 10px;
    bottom: 10px;
    padding-inline: 10px;
    opacity: 0.5;
}
.selectble:hover .dynimc-bg {
    inset: 0;
    opacity: 1;
}
.selectble-active .dynimc-bg {
    inset: 0;
}

.selectble-active {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    background-color: #63cfc9aa;
    box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.26);
}

.checked-box {
    fill: none;
    height: 24px;
    width: 24px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 0px 5px #0003;
    transition: initial 0.2s ease-in-out;
}

.checked {
    box-shadow: 0px 0px 5px #F87171;
    background-color: #F87171;
    fill: #fff;
}


.Toggle-button{
    margin-block:auto;
    margin-inline: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    align-self: flex-start;
}

.Toggle-button .Toggleed-bg{
    padding: 2px;
    display: flex;
    margin: 0 4px;
    min-width: 48px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
}
.Toggle-button .Toggleed-bg span{
   padding: 12px;
   border-radius: 24px;
   background-color: #ffffff;
}

.Toggleed .Toggleed-bg{
    padding-right: 22px;
    background-color: #F87171;
}

.wrap-conatiner{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 14px;
 }

.red-card {background-color: #dd3643;}
.green-card {background-color: #56e181;}
.cyan-card {background-color: #63cfc9;}

.cyan-text {color: #63cfc9;}
.green-text {color: #56e181;}
.red-text {color: #F87171 ;}
.black-text {color: #2d303e;}
.gray-text {color: #393c4a;}

.orange-card {background-color: #f48114;}
.orange-card-1 {background-color: #ffa44e;}
.orange-card-2 {background-color: #f9c089;}
.orange-text {color: #f48114;}
.orange-text-2 {color: #ffa44e;}
.orange-text-3 {color: #f9c089;}


.lds-ripple {
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #63cfc9;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  


.loading-count {
    align-items: center;
    justify-content: center;
    display:flex;
    position: relative;
    top: 4px;
}

.loading-count span{
    animation: count-farme 1.5s  cubic-bezier(0.175, 0.85, 0, 1.275) infinite;
    background-color: #f48114;
    border-radius: 10px;
    padding: 4px;
}

.loading-count span:nth-child(1) {
    animation-delay: 0ms;
}
.loading-count span:nth-child(2) {
    animation-delay: -100ms;
}
.loading-count span:nth-child(3) {
    animation-delay: -200ms;
}
.loading-count span:nth-child(4) {
    animation-delay: -300ms;
}
.loading-count span:nth-child(5) {
    animation-delay: -600ms;
}


@keyframes count-farme {
    0% {
        transform: translate3d(15px,-2px,0);
        opacity: 0;
    }
    50% {
        transform: translateX(-5px);
        opacity: 1;
    }
    100% {
        transform: translateX(-15px);
        opacity: 0;
    }
}
